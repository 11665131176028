<template>
    <div class="ws_brand_setting">
        <div class="ws_brand_setting_header">
            <h3>{{ brandMusicInfo.brandMusicNm }}</h3>
            <div class="close_box" @click="$emit('closePopup')">
                <img src="/media/img/close_btn.png" alt="" />
            </div>
        </div>

        <div class="ws_brand_content_preview">
            <div @click="onPlay(brandMusicInfo.brandMusicId, $event)">
                <img id="bImg" src="/media/img/brand_Play.svg" alt="" />
                <div class="sound_updown" style="width: 100%; padding: 0 5px; display: none">
                    <span></span>
                    <!-- <span v-bind:style="{width: $store.state.playNav + '%'}"></span> -->
                    <input id="seekslider" type="range" min="0" max="100" step="1" value="0" />
                    <video id="bVideo" width="100%" height="1" hidden playsinline></video>
                </div>
                <p>미리듣기</p>
            </div>
        </div>

        <div class="ws_brand_setting_content">
            <div class="player_clock player_clock_1" :class="brandMusicInfo.spaceId != null ? '' : 'disabled'">
                <div class="player_clock_btn" @click="changePlayType('S')">
                    <input type="radio" name="time" :checked="playType === 'S'" />
                    <label for="">지정시간</label>
                </div>
                <div class="select-box-area" :class="playType === 'S' && brandMusicInfo.spaceId != null ? '' : 'disabled'" @click="openTimeSetting">
                    <div class="select-box" id="select_box_big">
                        {{ selectTime }}
                    </div>
                </div>
            </div>
            <div class="player_clock" :class="brandMusicInfo.spaceId != null ? '' : 'disabled'">
                <div class="player_clock_btn" @click="changePlayType('R')">
                    <input type="radio" name="time" :checked="playType === 'R'" />
                    <label for="">반복주기</label>
                </div>
                <label class="select-box-label">운영시간</label>
                <div class="select-box-area" :class="playType === 'R' && brandMusicInfo.spaceId != null ? '' : 'disabled'" @click="openStartTimeSetting">
                    <div class="select-box" id="select_box_small">{{ startTime }} 부터</div>
                </div>
                <div class="select-box-area" :class="playType === 'R' && brandMusicInfo.spaceId != null ? '' : 'disabled'" @click="openEndTimeSetting">
                    <div class="select-box" id="select_box_small">{{ endTime }} 까지</div>
                </div>
                <label class="select-box-label">주기</label>
                <div class="select-box-area" :class="playType === 'R' && brandMusicInfo.spaceId != null ? '' : 'disabled'" @click="openTimeRepeat">
                    <div class="select-box" id="select_box_small">{{ repeatTime }}시간마다</div>
                </div>
            </div>
        </div>

        <div class="form_bottom">
            <button style="text-align: center" :class="brandMusicInfo.spaceId != null ? '' : 'disabled'" @click="updateTime()">저장</button>
        </div>

        <div v-show="popupStatus" class="time_setting">
            <div class="time_setting_box">
                <div class="setting_box_top">
                    <h2 v-if="playType === 'S'">지정시간</h2>
                    <h2 v-else>반복주기</h2>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closePopup">
                        <path d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z" fill="white" />
                    </svg>
                </div>
                <div class="setting_box_mid">
                    <DateTimePicker v-model="selectedTime" type="time" minute-interval="5"></DateTimePicker>
                </div>
                <div class="setting_box_bot" @click="setTime">
                    <p>확인</p>
                </div>
            </div>
        </div>

        <div v-show="popupStatus2" class="time_repeat">
            <div class="time_repeat_box">
                <div class="repeat_box_top">
                    <h2>반복주기</h2>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" @click="closePopup">
                        <path d="M12.0007 10.5862L16.9507 5.63623L18.3647 7.05023L13.4147 12.0002L18.3647 16.9502L16.9507 18.3642L12.0007 13.4142L7.05072 18.3642L5.63672 16.9502L10.5867 12.0002L5.63672 7.05023L7.05072 5.63623L12.0007 10.5862Z" fill="white" />
                    </svg>
                </div>
                <div class="repeat_box_mid">
                    <div class="box_1" style="-webkit-touch-callout: none" @touchstart="touchStart(1)" @touchend="touchEnd(1)">
                        <p style="-webkit-touch-callout: none">1시간</p>
                    </div>
                    <div class="box_2" style="-webkit-touch-callout: none" @touchstart="touchStart(2)" @touchend="touchEnd(2)">
                        <p style="-webkit-touch-callout: none">2시간</p>
                    </div>
                    <div class="box_3" style="-webkit-touch-callout: none" @touchstart="touchStart(3)" @touchend="touchEnd(3)">
                        <p style="-webkit-touch-callout: none">3시간</p>
                    </div>
                    <div class="box_6" style="-webkit-touch-callout: none" @touchstart="touchStart(6)" @touchend="touchEnd(6)">
                        <p style="-webkit-touch-callout: none">6시간</p>
                    </div>
                    <div class="box_12" style="-webkit-touch-callout: none" @touchstart="touchStart(12)" @touchend="touchEnd(12)">
                        <p style="-webkit-touch-callout: none">12시간</p>
                    </div>
                </div>
            </div>
        </div>

        <MusicAlert v-if="musicAlertStatus" @closeAlert="musicAlertStatus = false" />
    </div>
</template>

<script>
import MusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import DateTimePicker from 'vue-drumroll-datetime-picker';
import 'vue-drumroll-datetime-picker/dist/style.css';
import VueCookies from 'vue-cookies';
import axios from 'axios';
import Hls from 'hls.js';
import $ from 'jquery';

export default {
  name: 'mobileWebCastingBrnadMusicSetting',

  props: {
    brandMusicInfo: Object
  },

  components: {
    MusicAlert,
    DateTimePicker
  },

  data () {
    return {
      playType: this.brandMusicInfo.playType,
      selectTime: this.brandMusicInfo.selectTime,
      startTime: this.brandMusicInfo.startTime,
      repeatTime: this.brandMusicInfo.repeatTime,
      endTime: this.brandMusicInfo.endTime,
      repeatType: 'start',

      // 음원 재생 URL
      playUrl: null,

      // 중복 재생 불가 얼럿
      musicAlertStatus: false,

      // 시간 설정 팝업
      popupStatus: false,
      selectedTime: '09:00',

      // 반복 주기
      popupStatus2: false
    };
  },

  methods: {
    touchStart (idx) {
      // document.getElementsByClassName('box_' + idx)[0].style.background = 'rgba(255, 255, 255, 0.08)';
      // document.getElementsByClassName('box_' + idx)[0].children[1].style.display = 'block';
    },

    touchEnd (idx) {
      // document.getElementsByClassName('box_' + idx)[0].style.background = 'transparent';
      // document.getElementsByClassName('box_' + idx)[0].children[1].style.display = 'none';
      this.setRepeat(idx);
    },

    changePlayType (val) {
      this.playType = val;
    },

    // 시간 설정 팝업 열기
    openTimeSetting () {
      this.selectedTime = this.playType === 'S' ? this.selectTime : this.startTime;
      this.popupStatus = true;

      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        document.getElementsByClassName('time_setting_box')[0].style.height = '250px';
      }, 100);
    },
    openStartTimeSetting () {
      this.repeatType = 'start';
      this.selectedTime = this.startTime;
      this.popupStatus = true;
      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        document.getElementsByClassName('time_setting_box')[0].style.height = '250px';
      }, 100);
    },
    openEndTimeSetting () {
      this.repeatType = 'end';
      this.selectedTime = this.endTime;
      this.popupStatus = true;
      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        document.getElementsByClassName('time_setting_box')[0].style.height = '250px';
      }, 100);
    },

    // 시간 설정 팝업 닫기 / 시간 적용
    setTime () {
      if (this.playType === 'S') {
        this.selectTime = this.selectedTime;
      } else {
        if (this.repeatType === 'start') {
          this.startTime = this.selectedTime;
        } else {
          this.endTime = this.selectedTime;
        }
      }
      document.getElementsByClassName('time_setting_box')[0].style.height = '0px';

      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        this.popupStatus = false;
      }, 150);
    },

    openTimeRepeat () {
      this.popupStatus2 = true;

      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        document.getElementsByClassName('time_repeat_box')[0].style.height = '276px';
      }, 100);
    },

    setRepeat (val) {
      this.repeatTime = val;
      document.getElementsByClassName('time_repeat_box')[0].style.height = '0px';

      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        this.popupStatus2 = false;
      }, 150);
    },

    closePopup () {
      document.getElementsByClassName('time_setting_box')[0].style.height = '0px';
      document.getElementsByClassName('time_repeat_box')[0].style.height = '0px';

      // 애니메이션 효과를 위한 딜레이 설정
      setTimeout(() => {
        this.popupStatus = false;
        this.popupStatus2 = false;
      }, 150);
    },

    // 브랜드 음원 설정 업데이트
    updateTime () {
      var brandMusicId = this.brandMusicInfo.brandMusicId;
      var spaceId = VueCookies.get('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      if (this.playType === 'S') {
        var selectTime = this.selectTime;

        axios
          .post('/api/player/updateSpaceMusicSelectTime', { spaceId, brandMusicId, selectTime }, { headers })
          .then(() => {
            this.$emit('getBrandList');
            this.$emit('closePopup');
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        var repeatTime = String(this.repeatTime);
        var startTime = String(this.startTime);
        const endTime = this.endTime;
        axios
          .post('/api/player/updateSpaceMusicStartTime', { spaceId, brandMusicId, startTime, endTime }, { headers })
          .then((res) => {
            if (res.data.resultCd === '0000') {
              axios
                .post('/api/player/updateSpaceMusicRepeatTime', { spaceId, brandMusicId, repeatTime }, { headers })
                .then((response) => {
                  if (response.data.resultCd === '0000') {
                    this.$emit('getBrandList');
                    this.$emit('closePopup');
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    onPlay (url) {
      if (!this.$store.state.isPlay) {
        var videoId = document.getElementById('bVideo').id;
        var video = document.getElementById(videoId);
        var seek = document.getElementById('seekslider').id;
        var imgId = document.getElementById('bImg').id;
        if (this.playUrl == null || this.playUrl !== url) {
          this.playerListener(url, seek, videoId, imgId);
        }
        $('#bImg').attr('src', (index, attr) => {
          if (attr.match('_Play')) {
            video.play();
            return attr.replace('_Play.svg', '_Pause.svg');
          } else {
            video.pause();
            return attr.replace('_Pause.svg', '_Play.svg');
          }
        });
      } else {
        this.musicAlertStatus = true;
      }
    },

    playerListener (url, seek, videoId, imgId) {
      this.playUrl = url;
      // const _store = this.$store;
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      let hls = null;
      const seekslider = document.getElementById(seek);
      let video = document.getElementById(videoId);

      seekslider.addEventListener('click', vidSeek, false);
      video.addEventListener('timeupdate', seektimeupdate, false);
      video.addEventListener('ended', () => {
        $('#' + imgId).attr('src', (index, attr) => {
          video.pause();
          return attr.replace('_Pause.svg', '_Play.svg');
        });
      });

      function vidSeek () {
        var seekto = video.duration * (seekslider.value / 100);
        video.currentTime = seekto;
      }

      function seektimeupdate () {
        var newtime = video.currentTime * (100 / video.duration);
        seekslider.value = newtime;
        // _store.commit("setPlayNav", newtime);
      }

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic (url) {
        const tg = window.location.host.indexOf('localhost') !== -1 ? 'http://13.209.152.125:10084/stream/getMusic?' + url : '/stream/getMusic?' + url;
        // const tg = "http://13.209.152.125:10084" + url;
        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          // addListener(); //add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById(videoId);
          const pa = video.parentElement;

          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement('video');
          v.id = videoId;
          v.playsInline = true;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;

          v.addEventListener('ended', () => {
            $('#' + imgId).attr('src', (_, attr) => {
              return attr.replace('_Pause.svg', '_Play.svg');
            });
          });

          // addListener(); //add video player listener
          v.querySelector('source').src = tg;
          v.play(); // autoplay for next song
        } else {
          // todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    }
  }
};
</script>
<style src="@/assets/css/drumrollpicker.css"></style>
<style scoped>
/* common */
.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.ws_brand_setting {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background: #151515;
  border-radius: 20px 20px 0px 0px;
  z-index: 7;
}

.ws_brand_setting .ws_brand_setting_header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 18px;
  justify-content: space-between;
}

.ws_brand_setting .ws_brand_setting_header h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #ffffff;
}

.ws_brand_setting .ws_brand_setting_header .close_box {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
}

.ws_brand_setting .ws_brand_setting_header .close_box:hover {
  background: rgba(255, 255, 255, 0.08);
}

.ws_brand_setting .ws_brand_setting_header .close_box img {
  width: 18px;
  height: 18px;
}

.ws_brand_setting .ws_brand_content_preview {
  width: 100%;
  padding: 0 24px 24px;
}

.ws_brand_setting .ws_brand_content_preview div {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  border-radius: 100px;
}

.ws_brand_setting .ws_brand_content_preview div p {
  margin-left: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -1px;
  color: #ffffff;
}

.ws_brand_setting .ws_brand_content_preview div img {
  width: 24px;
  height: 24px;
}

.ws_brand_setting .ws_brand_setting_content {
  width: 100%;
  padding: 0 24px 0;
}

/* 시간 설정 */
.ws_brand_setting .ws_brand_setting_content .player_clock {
  margin-bottom: 24px;
}

.ws_brand_setting .ws_brand_setting_content .player_clock .player_clock_btn {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.ws_brand_setting .ws_brand_setting_content .player_clock .select-box-area {
  margin-bottom: 8px;
}

.ws_brand_setting .ws_brand_setting_content .player_clock .select-box-area .select-box {
  display: flex;
  align-items: center;
}

/* 시간 설정 INPUT */
.ws_brand_setting .ws_brand_setting_content .player_clock .player_clock_btn input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: hsla(0, 0%, 100%, 0.16);
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  margin-right: 6px;
}

.ws_brand_setting .ws_brand_setting_content .player_clock .player_clock_btn input:checked {
  background: #ff2d55;
  border: 5px solid #fff;
}

.ws_brand_setting .ws_brand_setting_content .player_clock .player_clock_btn label {
  margin-bottom: 0;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

/* 시간 설정 팝업 */
.time_setting {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 70;
}

.time_setting .time_setting_box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  border-radius: 20px 20px 0px 0px;
  transition: all 0.5s ease 0s;
}

.time_setting .time_setting_box .setting_box_top {
  display: flex;
  align-content: center;
  justify-content: space-between;
  height: 40px;
  padding: 12px 16px 2px;
  border-radius: 20px 20px 0 0;
}

.time_setting .time_setting_box .setting_box_top h2 {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.time_setting .time_setting_box .setting_box_mid {
  height: 160px;
  color: rgba(225, 225, 225, 0.4);
}

.time_setting .time_setting_box .setting_box_mid >>> .v-drumroll-picker__container {
  background: transparent !important;
}

.time_setting .time_setting_box .setting_box_bot {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
}

.time_setting .time_setting_box .setting_box_bot p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: -0.5px;
  color: #ff2d55;
}

/* 반복 주기 팝업 */
.time_repeat {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 70;
}

.time_repeat .time_repeat_box {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #151515;
  border-radius: 20px 20px 0px 0px;
  transition: all 0.5s ease 0s;
}

.time_repeat .time_repeat_box .repeat_box_top {
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 20px 20px 0 0;
}

.time_repeat .time_repeat_box .repeat_box_top h2 {
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}

.time_repeat .time_repeat_box .repeat_box_mid {
  height: 220px;
  overflow: auto;
}

.time_repeat .time_repeat_box .repeat_box_mid div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 12px 16px;
}

.time_repeat .time_repeat_box .repeat_box_mid div p {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(255, 255, 255, 0.87);
}

/* BOTTOM */
.form_bottom {
  position: absolute;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.06), rgba(255, 255, 255, 0.06)), #151515;
}

.select-box-label {
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: 400;
  margin-bottom: 8px;
  display: inline-block;
}
</style>
