<template>
    <div class="ws_brand_content">
        <div class="ws_brand_content_header">
            <h3>브랜드 음원</h3>
            <div class="close_box" @click="$emit('closePopup')">
                <img src="/media/img/close_btn.png" alt="" />
            </div>
        </div>

        <div class="ws_brand_content_list">
            <div class="ws_brand_item" :class="'alarm_' + i" v-for="(item, i) in brandMusic" :key="i" style="-webkit-touch-callout: none">
                <div class="ws_brand_item_name" @click="openSetting(item)">
                    <p class="common_icon" v-if="item.spaceId == null" style="-webkit-touch-callout: none">공통</p>
                    <p>{{ item.brandMusicNm }}</p>
                    <img src="/media/img/btn_icon.png" alt="" style="-webkit-touch-callout: none" />
                </div>
                <div class="ws_brand_item_toggle">
                    <div class="alarm_box" :class="item.spaceId == null ? 'po_event' : ''">
                        <span :class="{ active1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                        <span class="move" :class="{ active1_1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                    </div>
                </div>
            </div>
        </div>

        <BrnadMusicSetting v-if="popupStatus" :brandMusicInfo="brandMusicInfo" @getBrandList="$emit('getBrandList')" @closePopup="popupStatus = false" />
    </div>
</template>

<script>
import BrnadMusicSetting from '@/components/modal/PlayerWS/MoWsBrandSetting.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'mobileWebCastingBrandContent',

  props: {
    brandMusic: Array
  },

  components: {
    BrnadMusicSetting
  },

  data () {
    return {
      brandMusicInfo: null,
      popupStatus: false
    };
  },

  methods: {
    openSetting (item) {
      this.brandMusicInfo = item;
      this.popupStatus = true;
    },

    updateBrandMusicPlayAt (item) {
      var brandMusicId = item.brandMusicId;
      var spaceId = VueCookies.get('spaceId');
      var aToken = VueCookies.get('aToken');
      var headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/player/updateBrandSpaceMusicPlayAt', { spaceId, brandMusicId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            item.playAt = item.playAt === 'Y' ? 'N' : 'Y';
            this.$emit('getBrandList');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>
<!-- <style src="@/assets/css/drumrollpicker.css"></style> -->
<style scoped>
.ws_brand_content {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  max-height: 255;
  background: #151515;
  border-radius: 20px 20px 0px 0px;
  z-index: 6;
}

.ws_brand_content .ws_brand_content_header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 18px;
  justify-content: space-between;
}
.ws_brand_content .ws_brand_content_header h3 {
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #ffffff;
}
.ws_brand_content .ws_brand_content_header .close_box {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.5s;
}
.ws_brand_content .ws_brand_content_header .close_box:hover {
  background: rgba(255, 255, 255, 0.08);
}
.ws_brand_content .ws_brand_content_header .close_box img {
  width: 18px;
  height: 18px;
}

.ws_brand_content .ws_brand_content_list {
  width: 100%;
  height: calc(100% - 58px);
  overflow: auto;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item {
  position: relative;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .ws_brand_item_name {
  width: 80%;
  max-width: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .ws_brand_item_name p {
  margin-right: 4px;
  color: #fff;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .ws_brand_item_name img {
  width: 12px;
  height: 12px;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .ws_brand_item_name .common_icon {
  font-family: 'Noto Sans KR';
  width: 32px;
  height: 23px;
  padding: 2px 4px;
  margin-right: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px !important;
  line-height: 19px;
  letter-spacing: -0.5px;
  background: #3b3b3b;
  border-radius: 100px;
  color: #ffffff;
}

.ws_brand_content .ws_brand_content_list .ws_brand_item .ws_brand_item_toggle {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(0, -50%);
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .alarm_box {
  position: relative;
  cursor: pointer;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .alarm_box span {
  width: 32px;
  height: 10px;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .alarm_box span.move {
  left: -4px;
  width: 16px;
  height: 16px;
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .alarm_box span.active1 {
  background: rgba(255, 45, 85, 0.6);
}
.ws_brand_content .ws_brand_content_list .ws_brand_item .alarm_box span.move.active1_1 {
  transform: translate(20px, -50%);
  background: #ff2d55;
  transition: all 0.3s;
}
</style>
